import okanPOI from './okanPOI';

/* eslint-disable no-console*/

//var mapThis = null;

class geoPoint{
    constructor(){
        this.screen = {
            x: 0,
            y: 0,
        };
        this.coords = {
            long: 0,
            lat:  0
        }
    }

    set(e,canvas = this.drawingCanvas){
        var boundingRect = canvas.getBoundingClientRect();
        this.screen.x = e.originalEvent.clientX - boundingRect.left - canvas.offsetLeft ; 
        this.screen.y = e.originalEvent.clientY - boundingRect.top - canvas.offsetTop ; 
        this.coords.long = e.lngLat.wrap().lng;
        this.coords.lat = e.lngLat.wrap().lat;
        //console.log(this.coords.long, this.coords.lat)
    }
}

export default class okanMap{
    constructor(caller, id = null){

        this.okanMain = caller;
        this.map = null;
        this.id = id;
        this.mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
        //this.server = "https://maps.okenite.xyz";
        this.mapStyle = 'https://securemaps.okan3d.com/style.json';
        this.navigation = new this.mapboxgl.NavigationControl();

        this.drawingCanvas = null;      // canvas pour dessiner une nouvelle zone
        this.drawingContext = null;

        this.mouseCursor = new geoPoint();
        this.leftMouseButtonDown = false;
        this.allowDragging = false;
        this.dragStart = new geoPoint();
        this.dragEnd = new geoPoint();

        this.bounds = null;

       // mapThis = this;
    }

    init(){
        //console.log("Map init");
        this.drawingCanvas = document.getElementById('drawingCanvas');
        this.drawingContext = this.drawingCanvas.getContext('2d');

        this.drawingContext.canvas.width = this.drawingContext.canvas.offsetWidth;
        this.drawingContext.canvas.height = this.drawingContext.canvas.offsetHeight;

        //this.mapboxgl.accessToken = 'pk.eyJ1Ijoic3RlZm1hcGJveCIsImEiOiJjazIzNmM5MjMwMHpuM25wOTN6NmViNzFxIn0.0F7oZYU-pM8-UbNHJsH9Dw';

        if (this.okanMain.admin){
            console.log("admin", this.okanMain.mapId);
            this.map = new this.mapboxgl.Map({
                container: document.getElementById('map'),
                //style: 'mapbox://styles/mapbox/streets-v11', //this.mapStyle,
                style: this.mapStyle,
                center : [5.95, 46.167],
                zoom: 5.95,
                pitch: 55,
                bearing: 0,
                renderWorldCopies: false,
                hash: true,
                attributionControl:true,
                preserveDrawingBuffer:true,
                navigationControl:true,
                antialias: true, 
            });
        }
        else{
            //console.log("client", this.okanMain.mapId);
            this.map = new this.mapboxgl.Map({
                container: document.getElementById('map'),
                //style: 'mapbox://styles/mapbox/streets-v11', //this.mapStyle,
                style: this.mapStyle,
                center : this.okanMain.map_data[this.okanMain.mapId].map.center,
                zoom: this.okanMain.map_data[this.okanMain.mapId].map.maxZoom,
                pitch: 55,
                bearing: 0,
                renderWorldCopies: false,
                hash: false,
                attributionControl:true,
                preserveDrawingBuffer:true,
                navigationControl:true,
                antialias: true, 
                maxBounds: this.okanMain.map_data[this.okanMain.mapId].map.bounds,
            });
        }

        /*********************************************************************************************************************
         ************************           events listeners            **************************************************** 
        **********************************************************************************************************************/
        this.map.on('load', () => {
            this.map.addControl(this.navigation);
            this.map.setPaintProperty('background', 'background-color', "hsl(47, 20%, 93%)");
            this.map.setPaintProperty('landcover_wood', 'fill-opacity', 0.3);
            this.map.setPaintProperty('park', 'fill-opacity', 0.3);
            this.map.setPaintProperty('landcover_grass', 'fill-opacity', 0.3);
            this.map.setPaintProperty('landuse_overlay_national_park', 'fill-opacity', 0.3);
        });

        this.map.on('mousemove', e => { 
            this.mouseCursor.set(e, this.drawingCanvas);
            if (this.leftMouseButtonDown && this.allowDragging)
                this._dragUpdate();
            //console.log(this.mouseCursor.screen.x + " - " + this.mouseCursor.screen.y );
            //console.log(this.mouseCursor.coords.long + " - " + this.mouseCursor.coords.lat );
        });

        this.map.on('mouseup',e => {
            if(e.originalEvent.button == 0 ){
                this.leftMouseButtonDown = false;
                if (this.allowDragging)
                   this._dragEnd(); 
            }
        });

        this.map.on('mousedown',e => { 
            if(e.originalEvent.button == 0 ){
                this.dragStart.set(e,this.drawingCanvas);
                this.leftMouseButtonDown = true;
                //console.log("map click")
                if(this.okanMain.selectedPOI != null && this.okanMain.selectedPOI.isBeingDragged ){
                    this.okanMain.selectedPOI.setRelocEnd();
                }                
            }               
        });

        this.map.on('dblclick', e =>{
            e.originalEvent.stopPropagation();
            if(this.okanMain.admin && this.okanMain.mapId != -1 && (this.okanMain.selectedPOI == null || !this.okanMain.selectedPOI.isBeingDragged )){
                this.okanMain.creationMode = true;
                this.createMarker({long:null, lat:null});
            }
        }),

        window.addEventListener('resize', ()=>{
            this._resize();
        })
        this._resize();
    }

    reset(params){
        console.log('call reset',params);
        this.drawingCanvas = document.getElementById('drawingCanvas');
        this.drawingContext = this.drawingCanvas.getContext('2d');

        this.drawingContext.canvas.width = this.drawingContext.canvas.offsetWidth;
        this.drawingContext.canvas.height = this.drawingContext.canvas.offsetHeight;        
        this.map = new this.mapboxgl.Map({
            container: document.getElementById('map'),
            style: this.mapStyle,
            center : params.center,
            zoom: params.zoom,
            pitch: 55,
            bearing: 0,
            renderWorldCopies: false,
            hash: false,
            attributionControl:true,
            preserveDrawingBuffer:true,
            navigationControl:true,
            antialias: true, 
            maxBounds: params.bounds,
        });
        window.addEventListener('resize', ()=>{
            this._resize();
        })
        this._resize();        
    }

    

    setDrawingMode(bool){
        this.allowDragging = bool;
        if(this.allowDragging){
            this.map.dragPan.disable();
        }
        else{
            this.drawingContext.clearRect(0, 0, this.drawingCanvas.width, this.drawingCanvas.height);
            this.map.dragPan.enable();
        }
    }


    createMarker(params){
        //console.log(params);
        var _poi = new okanPOI({
            long: params.long == null ? this.mouseCursor.coords.long : params.long,
            lat: params.lat == null ?  this.mouseCursor.coords.lat : params.lat,
            name: params.name ,
            id: params.id,
            externalID : params.id,
            desc : params.desc,
            url : params.url,
            biens : params.biens,
            poiType : params.poiType,
            htmlPopup : params.htmlPopup,
            image : params.image,
            ville : params.ville,
            poiStradim : params.poiStradim == null ? false : params.poiStradim,
            okanMain : this.okanMain
        });

/*
        _poi.biens.forEach(element => {
            element.options_expanded = false ;  
        });
*/
        _poi.div = document.createElement('div');
        _poi.div.className = 'marker';
        _poi.div.setAttribute('name', _poi.name);

        if(params.poiType != null && params.poiType != undefined){
            _poi.div.classList.add('type_'+params.poiType);    

        }else{
            _poi.div.classList.add('type_20');    //ecoles
            _poi.div.classList.add('temporary');
        }

        _poi.marker = new this.mapboxgl.Marker(_poi.div)
        .setLngLat([_poi.long , _poi.lat])
        .setPopup(new this.mapboxgl.Popup({
            closeButton: false,
            closeOnClick: true
        })
            .setHTML(""))
        .addTo(this.map);  
        
        _poi.popup_update();
        _poi.setListeners();

        return _poi;
    }

    _dragUpdate(){
        this.drawingContext.clearRect(0, 0, this.drawingCanvas.width, this.drawingCanvas.height);
        this.drawingContext.fillStyle = 'rgba(225,0,0,0.5)';
        this.drawingContext.fillRect(this.dragStart.screen.x, this.dragStart.screen.y, 
            this.mouseCursor.screen.x - this.dragStart.screen.x,  this.mouseCursor.screen.y - this.dragStart.screen.y);
    }

    _dragEnd(){
        this.dragEnd = this.mouseCursor;
            var minLong, minLat, maxLong, maxLat
            if (this.dragStart.coords.long > this.dragEnd.coords.long){
                minLong =  this.dragEnd.coords.long;
                maxLong = this.dragStart.coords.long;
            }
            else{
                maxLong =  this.dragEnd.coords.long;
                minLong = this.dragStart.coords.long;
            }
            if (this.dragStart.coords.lat > this.dragEnd.coords.lat){
                minLat =  this.dragEnd.coords.lat;
                maxLat = this.dragStart.coords.lat;
            }
            else{
                maxLat =  this.dragEnd.coords.lat;
                minLat = this.dragStart.coords.lat;
            }    
        this.bounds = [
            [minLong,minLat],
            [maxLong,maxLat]
        ];    

        console.log(this.bounds);
  
        this.map.fitBounds(this.bounds);
        this.drawingContext.clearRect(0, 0, this.drawingCanvas.width, this.drawingCanvas.height);
        this.okanMain.setNewZone();
    }

    _resize(){
        this.drawingContext.canvas.width = this.map._canvas.clientWidth;
        this.drawingContext.canvas.height = this.map._canvas.clientHeight;   
        //console.log("canvas size", this.drawingCanvas.width +" - "+ this.drawingCanvas.height)
    }
}