<template>
  <div id="app">

    <div 
      id="goodURL"
    >
      <mapLayer 
        v-bind:okanMain="okanMain"
      />
      <okanAdmin
        v-bind:okanMain="okanMain"
        v-bind:pictos="pictos"
        v-bind:biens_optionList="biens_optionList"
      />
      <clientUI
        v-bind:okanMain="okanMain"
        v-bind:pictos="pictos"
        v-bind:biens_optionList="biens_optionList"
      />
    </div>

  </div>
</template>

<script>

import mapLayer from './components/mapLayer.vue'
import okanAdmin from './components/okanAdmin.vue'
import clientUI from './components/clientUI.vue'
import okanMain from './okanMain.js';
import picto_data from "../public/data/pictos.json"

export default {
  name: 'app',
  components: {
    mapLayer,
    okanAdmin,
    clientUI,
  },
	data: function() {
			return {
        okanMain: new okanMain(this),
        pictos : this.orderPictos(picto_data),
        biens_optionList : ["terrasse","jardin"],
			}
  },    
  created() {
    
    
  },
  mounted(){
    this.okanMain.loadFromCloud();
  },
  methods : {   
    orderPictos(data){    // order pictos by category : to change order simply change order of array 
      var tmpArray = []
      var order = [6,1,2,3,4,5];
      for(var i= 0; i< order.length; i++){
          var tmpMatches = data.filter(picto => {
              if (picto.category == order[i]) return picto;
          });
          tmpArray = tmpArray.concat(tmpMatches);
      }
      return tmpArray;      
    }
  }
}
</script>

<style src="./assets/css/main.css"></style>