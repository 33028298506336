
/* eslint-disable no-console*/
<template>
  <div
    id="okanAdmin"
    v-bind:okanMain="okanMain"
    v-bind:biens_optionList="biens_optionList"
    v-if="okanMain.admin"
  >
    <!--*****************************************************************************************
    **************************          sidebar         *****************************************
    *********************************************************************************************-->
    <div id="adminSidebar" v-if="!okanMain.map.allowDragging">
      <h3 class="stradimTitle">Choisissez ou créez une zone</h3>
      <select 
        id="zoneGeo" 
        v-model="okanMain.mapId" 
        @change="changeZone" 
        v-if="okanMain.map_data != null"
        v-bind:disabled="okanMain.admin_popup"
      >
        <option v-bind:value="-1">Sélectionnez une Zone</option>
        <option
          v-for="(option, optionIndex) in okanMain.map_data"
          v-bind:key="optionIndex"
          v-bind:value="option.map.id"
        >{{option.map.name}}</option>
      </select>
      <button 
        id="newZone" 
        class="stradimTitle"
        @click="setDraw" 
        v-bind:disabled="okanMain.admin_popup"
      >Nouvelle Zone</button>
      <button
        id="save"
        class="stradimTitle"
        v-if="!okanMain.admin_popup && okanMain.mapId != -1"
        @click="save()"
      >Enregistrer {{okanMain.map_data[okanMain.mapId].map.name}}</button>
      <hr />
      <div 
        id="howTo"
        v-if="okanMain.mapId == -1 "
      >
        <h1 class="stradimTitle">Aide</h1>
        <p>
          Avec une zone sélectionnée
        </p>
        <p>
          <u>Ajouter un point d'intérêt</u> : double clic à l'endroit voulu
        </p>
        <p>
          <u>Déplacer un point d'intérêt</u> : double clic sur le point d'intérêt
          puis clic gauche au nouvel endroit

        </p>
        <p>
          <u>Editer un point</u> : clic gauche sur le point puis clic sur le crayon
        </p>
        <p>
          <u>Supprimer un point</u> : clic gauche sur le point puis clic sur la poubelle
        </p>      
        <p>
          <u>Editer les options d'un bien</u> : <br/>
            <span class="puce2">►</span> grisé = sélectionné <br/>
            <span class="puce2">►</span> ne sélectionner qu'un seul élément : clic gauche<br/>
            <span class="puce2">►</span> sélectionner / désélectionner une option :<br/> ctrl + clic gauche<br/>
            <span class="puce2">►</span> sélectionner / désélectionner une suite d'options :<br/> shift + clic gauche<br/>

        </p>
        <div id="endScroll"></div>               
        <div 
          id="rememberToSave"
        >
          <span class="puce2">Pensez à enregistrer les modifications sur votre zone</span>
          
        </div>
        <span class="fleche">→</span>
        <button
            id="save"
            class="puce2 stradimTitle"
        >Enregistrer "nom de la zone"</button> 
      </div>
      <div 
        id="mapInfo"
        v-if="okanMain.mapId != -1"
      >
        <h3  class="stradimTitle">
          {{this.okanMain.map_data[this.okanMain.mapId].map.name}}
          <span
            id="infoID"
          >( id : {{this.okanMain.map_data[this.okanMain.mapId].map.id}} )</span>
        </h3>
      </div>
      <div id="programContainer" v-if="okanMain.mapId != -1">
        <div id="programs">
        <div
          class="stradimProgram"
          v-for="(program, programIndex) in okanMain.programs"
          v-bind:key="programIndex"
        >
          <div v-if="program != okanMain.selectedPOI">
            <div @click="pointToProgram(program)">
              <span class="puce">▲</span>
              {{program.name}}
            </div>
          </div>

          <div v-if="program == okanMain.selectedPOI">
            <div @click="pointToProgram(program)">
              <span class="puce">►</span>
              {{program.name}}
              <div class="deletePrgm" @click="deletePrgm(true,program.id)"></div>
              <div class="editPrgm" @click="editPrgm"></div>
              <div class="clear"></div>
            </div>
            <div
              class="listBiensSidebar"
            >
              <table id="listBiensSidebar">      
                <tbody id="biensContainer">
                  <tr v-for="(bien,bienIndex) in okanMain.selectedPOI.biens" v-bind:key="bienIndex">
                    <td class="col_1">
                      <span>{{bien.quantite}}</span>
                    </td>
                    <td class="col_2">
                      <span>{{bien.type}}</span>
                    </td>
                    <td class="col_3">
                      <span>{{bien.nb_pieces}}</span><span> pièces</span>
                    </td>
                    <td class="col_4">
                        <span>{{bien.superficie}}</span><span> m²</span>
                    </td>
                    <td class="col_5">
                        <div
                          v-if="options_expanded[bienIndex] != undefined && options_expanded[bienIndex]"                        
                        >
                          <span
                            v-for="(bienOption, bienOptionIndex) in bien.options"
                            v-bind:key="bienOptionIndex"                          
                          >
                            {{bienOption}}
                          </span>

                        </div>                
                        <span
                            v-if="options_expanded[bienIndex] == undefined || !options_expanded[bienIndex]"
                        >{{displayBienOptions(bien.options,14)}}</span>
                        <button
                            id="toggleOptions"
                            v-on:click="toggleOptions(bienIndex)"
                            v-bind:class="{'open' : options_expanded[bienIndex]}"
                        >▼</button>
                    </td>
                    <td class="col_6">
                      <span>{{bien.prix}}</span><span> €</span>
                    </td>
                  </tr>
                </tbody> 
              </table>             
            </div> 
          </div>
        </div>
        </div>
       <!-- <div id="endScroll"></div> -->
      </div>
    </div>
    <!-- fin sidebar -->
    <!--*****************************************************************************************
    **************************          POPUP           *****************************************
    *********************************************************************************************-->
    <div id="popup" v-if="okanMain.admin_popup && okanMain.selectedPOI != null">
      <div id="closePopup" @click="closeWithoutSaving"></div>
      <div id="creationTitle" v-if="okanMain.creationMode">
        <h1 class="stradimTitle" >
          Ajout d'un nouveau
          <button 
            id="newType" 
            @click="setNewType"
            class="stradimTitle" 
          >
            {{okanMain.selectedPOI.poiStradim ? "Programme Stradim" : "Point d'intérêt"}}
            <span class="puce">▼</span>
          </button>
        </h1>
      </div>
      <div id="editionTitle">
        <h1
          v-if="!this.okanMain.creationMode"
          class="stradimTitle"
        >Edition du {{okanMain.selectedPOI.poiStradim ? "Programme Stradim" : "Point d'intérêt"}} {{okanMain.selectedPOI.name}}</h1>
      </div>
      <!--*****************************************************************************************
      **************************          POI             *****************************************
      *********************************************************************************************-->
      <div id="POIDiv" v-show="!okanMain.selectedPOI.poiStradim">
        <div class="POI_Attr">
          <span class="puce">►</span>
          <span > Choisissez le type : </span>
          <select id="POIType" v-model="okanMain.selectedPOI.poiType" @change="updatePopup">
            <option
              v-for="(picto, pictoIndex) in pictos"
              v-bind:key="pictoIndex"
              v-bind:value="picto.id"
              v-bind:style="'{background: url(' + picto.url + ')}'"
            >
              <span>{{picto.name}}</span>
            </option>
          </select>
        </div>
        <div class="POI_Attr">
          <span class="puce">►</span>
          <span> Entrez un nom (facultatif) : </span>
          <input type="text" id="poi_name" v-model="okanMain.selectedPOI.name" />
        </div>
        <div class="POI_Attr">
          <span class="puce">►</span>
          <span> Entrez une description (facultatif) : </span>
          <br />
          <textarea id="poi_desc" cols="60" rows="8" v-model="okanMain.selectedPOI.desc"></textarea>
        </div>
      </div>
      <!--*****************************************************************************************
      **************************          programme         ***************************************
      *********************************************************************************************-->
      <div id="programme_Div" v-show="okanMain.selectedPOI.poiStradim">
        <div class="prgm_attr">
          <span class="puce">►</span>
          <span> Nom du programme : </span>
          <input type="text" id="program_name" v-model="okanMain.selectedPOI.name" />
        </div>
        <div class="prgm_attr">
          <span class="puce">►</span>
          <span> url : </span>
          <input type="text" id="program_url" v-model="okanMain.selectedPOI.url" />
        </div>
        <div class="prgm_attr">
          <span class="puce">►</span>
          <span> Biens : </span>
          <button id="addBien" @click="addBien">+</button>
        </div>
        <div class="prgm_attr" id="tableContent">
          <table id="listBiens">
            <thead>
              <tr>
                <th class="col_1">quantité</th>
                <th class="col_2">type</th>
                <th class="col_3">pièces</th>
                <th class="col_4">superficie</th>
                <th class="col_5">options</th>
                <th class="col_6">prix (€)</th>
                <th class="col_7"></th>
                <th class="col_8"></th>
              </tr>
            </thead>
            <tbody id="biensContainer">
              <tr v-for="(bien,bienIndex) in okanMain.selectedPOI.biens" v-bind:key="bienIndex">
                <td class="col_1">
                  <input type="number" min="1" step="1" v-model="bien.quantite" />
                </td>
                <td class="col_2">
                  <select v-model="bien.type">
                    <option value="Appartement">Appartement</option>
                    <option value="Maison">Maison</option>
                  </select>
                </td>
                <td class="col_3">
                  <input type="number" min="1" step="1" v-model="bien.nb_pieces" />
                </td>
                <td class="col_4">
                    <input type="number" min="0" v-model="bien.superficie" />  
                </td>
                <td class="col_5">
                    <select 
                        v-model="bien.options" 
                        multiple="true"
                        v-if="options_expanded[bienIndex] != undefined && options_expanded[bienIndex]"
                    >
                        <option 
                            v-for="(bienOption, bienOptionIndex) in biens_optionList"
                            v-bind:key="bienOptionIndex"
                        >
                            {{bienOption}}
                        </option>
                    </select>                  
                    <span
                         v-if="options_expanded[bienIndex] == undefined || !options_expanded[bienIndex]"
                    >{{displayBienOptions(bien.options,26)}}</span>
                    <button
                        id="toggleOptions"
                        v-on:click="toggleOptions(bienIndex)"
                        v-bind:class="{'open' : options_expanded[bienIndex]}"
                    >▼</button>
                </td>
                <td class="col_6">
                  <input type="number" min="0" v-model="bien.prix" />
                </td>
                <td class="col_7">
                  <button class="deleteBien" @click="deleteBien(bienIndex)"></button>
                </td>
                <td class="col_8">
                  <img
                    id="isValid"
                    v-bind:class="{'valid' : bien.quantite != null && 
                                            bien.type != null && 
                                            bien.nb_pieces!=null &&
                                            bien.superficie != null &&
                                            bien.prix != null }"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button 
        id="validate" 
        class="stradimTitle"
        @click="validatePOI" 
        v-if="okanMain.creationMode" 
      >Valider</button>
    </div>
  </div>
</template>

<script>

export default {
  name: "okanAdmin",
  components: {

  },
  props: {
    okanMain: Object,
    pictos: Array,
    biens_optionList: Array
  },
  data: function() {
    return {
        options_expanded : new Array(30).fill(false)
    }
  },
  mounted() {},
  created() {},
  destroyed() {},
  methods: {
    closeWithoutSaving() {
      this.okanMain.admin_popup = false;
      if (this.okanMain.creationMode) {
        var marker = document.getElementsByClassName("temporary")[0];
        marker.remove();
        this.okanMain.creationMode = false;
      } else {
        this.okanMain.selectedPOI.popup_update();
      }
      this.okanMain.selectedPOI = null;
    },
    setExpandedArray(){
      this.options_expanded = new Array(this.okanMain.selectedPOI.biens.length).fill(false);
    },
    toggleOptions(index){
      if (index > this.options_expanded.length || this.options_expanded[index] == undefined || this.options_expanded[index] == null){
        this.options_expanded[index] = false;
      }
      var ori_value = this.options_expanded[index]
      this.setExpandedArray();
      this.options_expanded[index] = !ori_value;
    },
    displayBienOptions(_array, maxLength = 20){
        var str = "";
        _array.forEach(element => {
            str += element + ", ";
        });
        if(str != "")
            str = str.substring(0,str.length -2);
        if(str.length > maxLength){
            str = str.substring(0,maxLength -3) + "...";
        }
        return str;
    },    
    save() {
      this.okanMain.prepareAndSave();
    },
    setDraw() {
      this.okanMain.map.setDrawingMode(true);
    },
    changeZone() {
      if (this.okanMain.mapId != -1)
        this.okanMain.loadZone(this.okanMain.mapId);
    },
    setNewType() {
      this.okanMain.selectedPOI.poiStradim = !this.okanMain.selectedPOI
        .poiStradim;
      if (this.okanMain.selectedPOI.poiStradim) {
        this.okanMain.selectedPOI.div.classList.add("type_Stradim");
      } else {
        if (this.okanMain.selectedPOI.div.classList.contains("type_Stradim"))
          this.okanMain.selectedPOI.div.classList.remove("type_Stradim");
      }
    },
    updatePopup() {
      this.okanMain.selectedPOI.marker_update();
    },
    addBien() {
      this.okanMain.selectedPOI.addBien();
    },
    deleteBien(index) {
      this.okanMain.selectedPOI.biens.splice(index, 1);
    },
    pointToProgram(program) {
      if (!this.okanMain.admin_popup) {
        if (program == this.okanMain.selectedPOI)
          this.okanMain.selectedPOI = null;
        else {
          this.okanMain.selectedPOI = program;
          this.okanMain.map.map.jumpTo({
            center: [
              this.okanMain.selectedPOI.long,
              this.okanMain.selectedPOI.lat
            ],
            zoom: 15
          });
        }
      }
    },
    deletePrgm(type, id) {
      this.okanMain.deletePOI(type, id);
    },
    editPrgm() {
      this.okanMain.editPOI();
    },
    validatePOI() {
      this.okanMain.selectedPOI.div.classList.remove("temporary");
      this.okanMain.selectedPOI.popup_update();
      if (this.okanMain.selectedPOI.poiStradim) {
        this.okanMain.selectedPOI.setId(this.okanMain.programs.length);
        this.okanMain.programs.push(this.okanMain.selectedPOI);
      } else {
        this.okanMain.selectedPOI.setId(this.okanMain.POIs.length);
        this.okanMain.POIs.push(this.okanMain.selectedPOI);
      }
      this.okanMain.admin_popup = false;
      this.okanMain.selectedPOI = null;
    }
  }
};
</script>

<style src="../assets/css/okanAdmin.css"></style>

