<template>
  <div
    id="clientUI"
    v-bind:okanMain="okanMain"
    v-bind:pictos="pictos"
    v-bind:biens_optionList="biens_optionList"
  >
    <div 
      id="sidebar" 
      v-show="false && !okanMain.admin && okanMain.autoloadPrgm == null && !okanMain.badUrlParams && okanMain.showLeftPanel"
      v-bind:class="{'rech_expanded' : rech_expanded}"
    >
      <div id="minimizeRech" @click="changeRechExpand" v-bind:class="{'rech_expanded' : rech_expanded}"></div>
      <div v-show="rech_expanded" id="sidebarContent">
        <h1>Trouver mon logement</h1>
        <!--<h3 class="subtitle">Typologie</h3>-->
        <div id="typologieBien">
          <!--************************************type***************************************-->
          <div id="type" v-bind:class="{'enabled' : rech.type.enabled}" class="criteria">
            <label class="switch">
              <input type="checkbox" id="div_allow_type" v-model="rech.type.enabled">
              <span class="slider allow_Check"></span>
            </label>
            <span> Type : </span>
            <input
              type="checkbox"
              id="chk_appart"
              name="chk_appart"
              value="Appartement"
              v-bind:disabled="!rech.type.enabled"
              v-model="rech.type.values"
            />
            <label for="chk_appart">Appartement </label>
            <input
              type="checkbox"
              id="chk_maison"
              name="chk_maison"
              value="Maison"
              v-bind:disabled="!rech.type.enabled"
              v-model="rech.type.values"
            />
            <label for="chk_maison">Maison</label>
          </div>
          <!--************************************ nb pieces ***************************************-->
          <div id="pieces" v-bind:class="{'enabled' : rech.pieces.enabled}" class="criteria">
            <label class="switch">
              <input type="checkbox" id="allow_pieces" v-model="rech.pieces.enabled">
              <span class="slider allow_Check"></span>
            </label>   
            <span> Pièces : entre </span>
            <input
              type="number"
              id="pieces_min"
              name="pieces_min"
              min="1"
              v-model="rech.pieces.min"
              v-bind:disabled="!rech.pieces.enabled"
            />
            <span> et </span>
            <input
              type="number"
              id="pieces_max"
              name="pieces_max"
              min="1"
              v-model="rech.pieces.max"
              v-bind:disabled="!rech.pieces.enabled"
            />
          </div>
          <!--************************************ superficie ***************************************-->
          <div id="superficie" v-bind:class="{'enabled' : rech.superficie.enabled}" class="criteria">
            <label class="switch">
              <input type="checkbox" id="allow_supeficie" v-model="rech.superficie.enabled">
              <span class="slider allow_Check"></span>
            </label> 
            <span> Superficie : entre </span>
            <input
              type="number"
              id="surface_min"
              name="surface_min"
              min="1"
              v-model="rech.superficie.min"
              v-bind:disabled="!rech.superficie.enabled"
            />
            <span> m² et </span>
            <input
              type="number"
              id="surface_max"
              name="surface_max"
              min="1"
              v-model="rech.superficie.max"
              v-bind:disabled="!rech.superficie.enabled"
            />
            <span> m²</span>
          </div>
          <!--************************************ options ***************************************-->
          <div id="options" v-bind:class="{'enabled' : rech.options.enabled}" class="criteria">
            <label class="switch">
              <input type="checkbox" id="allow_options" v-model="rech.options.enabled">
              <span class="slider allow_Check"></span>
            </label> 
            <span> </span> <!-- Options :  -->
            <div id="optionContainer">
              <div
                class="optionDiv"
                v-for="(option, optionIndex) in (biens_optionList)"
                v-bind:key="optionIndex"
              >
                <input
                  type="checkbox"
                  v-bind:name="'chkBox_option_' + optionIndex"
                  v-bind:disabled="!rech.options.enabled"
                  v-model="rech.options.values"
                  v-bind:value="biens_optionList[optionIndex]"
                />
                <label for="'chkBox_option_' + optionIndex">{{biens_optionList[optionIndex]}}</label>
              </div>
            </div>
            <div id="endOptions"></div>
          </div>

          <!--************************************ prix ***************************************-->
          <div id="prix" v-bind:class="{'enabled' : rech.prix.enabled}" class="criteria">
            <!--<input type="checkbox" class="allow_Check" name="allow_prix" v-model="rech.prix.enabled" /> -->
            <label class="switch">
              <input type="checkbox" id="allow_options" v-model="rech.prix.enabled">
              <span class="slider allow_Check"></span>
            </label>           
            <span> Prix : entre </span>
            <input
              type="number"
              id="prix_min"
              name="prix_min"
              min="0"
              step="20000"
              v-model="rech.prix.min"
              v-bind:disabled="!rech.prix.enabled"
            />
            <span> € et </span>
            <input
              type="number"
              id="prix_max"
              name="prix_max"
              step="20000"
              v-model="rech.prix.max"
              v-bind:disabled="!rech.prix.enabled"
            />
            <span> €</span>
          </div>
        </div>
        <h3 class="subtitle">Proximité</h3>
        <div
          id="user_defined_criterias"
          v-bind:class="{'enabled' : rech.allow_proxy}"
          class="criteria"
        >
          <label class="switch">
            <input type="checkbox" id="allow_options" v-model="rech.allow_proxy">
            <span class="slider allow_Check"></span>
          </label> 
          <button
            id="addCriteria"
            v-bind:disabled="!rech.allow_proxy"
            @click="newCriteria()"
            class="stradimTitle"
          >Ajouter un critère de proximité</button>
          <div id="criteriaList"  
            v-bind:class="{'enabled' : rech.allow_proxy}"
            v-bind:disabled="!rech.allow_proxy"
          >
            <table>
              <tbody>
                <tr
                  v-for="(criteria,criteriaIndex) in rech.proxy_criteria"
                  v-bind:key="criteriaIndex"
                  v-bind:disabled="!rech.allow_proxy"
                >
                  <td class="col_1">
                    <div v-if="criteriaIndex > 0">et</div>
                    <!--
                      <select v-if="criteriaIndex > 0">
                        <option value="et">et</option>
                        <option value="ou">ou</option>
                      </select>
                    -->
                  </td>

                  <td class="col_2">
                    <select 
                      v-model="criteria.more"
                      v-bind:disabled="!rech.allow_proxy"
                    >
                      <option value="false">à moins de</option>
                      <option value="true">à plus de</option>
                    </select>
                  </td>
                  <td class="col_3">
                    <input type="number" min="1" step="1" 
                      v-model="criteria.dist" 
                      v-bind:disabled="!rech.allow_proxy"
                    />
                  </td>
                  <td class="col_4">km d'un(e)</td>
                  <td class="col_5">
                    <select 
                      v-model="criteria.target"
                      v-bind:disabled="!rech.allow_proxy"
                    >
                      <option
                        v-for="(picto) in pictos"
                        v-bind:key="picto.id"
                        v-bind:value="picto.id"
                        v-bind:style="'{background: url(' + picto.url + ')}'"
                      >
                        <span>{{picto.name}}</span>
                      </option>
                    </select>
                  </td>
                  <td class="col_6">
                    <button 
                      @click="deleteCriteria(criteriaIndex)"
                      v-bind:disabled="!rech.allow_proxy"
                    ></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <button id="btn_search" @click="searchForMatches" class="stradimTitle">Rechercher</button>
        <h3 class="subtitle" v-if="rech.results.length > 0">Résultats</h3>
        <div id="searchResults" v-if="rech.okanMain != null">
          <div id="clientProgramContainer">
            <div
              class="stradimProgram"
              v-for="(program, programIndex) in rech.results"
              v-bind:key="programIndex"
            >
              <div v-if="program != rech.selectedProgram">
                <div @click="pointToProgram(program)">
                  <span class="puce">▲</span>
                  {{program.name}}
                </div>
              </div>

              <div v-if="program == rech.selectedProgram && program != null">
                <div @click="pointToProgram(program)">
                  <span class="puce">►</span>
                  {{program.name}}
                  <div class="clear"></div>
                </div>

                <div class="listBiens">
                  <table id="listBiensSidebar">
                    <tbody id="biensContainer">
                      <tr v-for="(bien,bienIndex) in program.biens" v-bind:key="bienIndex">
                        <td class="col_1">
                          <span>{{bien.quantite}}</span>
                        </td>
                        <td class="col_2">
                          <span>{{bien.type}}</span>
                        </td>
                        <td class="col_3">
                          <span>{{bien.nb_pieces}}</span>
                          <span>pièces</span>
                        </td>
                        <td class="col_4">
                          <span>{{bien.superficie}}</span>
                          <span>m²</span>
                        </td>
                        <td class="col_5">
                          <div
                            v-if="options_expanded[bienIndex] != undefined && options_expanded[bienIndex]"
                          >
                            <span
                              v-for="(bienOption, bienOptionIndex) in bien.options"
                              v-bind:key="bienOptionIndex"
                            >{{bienOption}}</span>
                          </div>
                          <span
                            v-if="options_expanded[bienIndex] == undefined || !options_expanded[bienIndex]"
                          >{{displayBienOptions(bien.options,7)}}</span>
                          <button
                            id="toggleOptions"
                            v-on:click="toggleOptions(bienIndex)"
                            v-bind:class="{'open' : options_expanded[bienIndex]}"
                          >▼</button>
                        </td>
                        <td class="col_6">
                          <span>{{bien.prix}}</span>
                          <span>€</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--*******************************************************************************************************
    *************************************       légende       *************************************************
    ***********************************************************************************************************-->
    <div id="legende" v-bind:class="{'expanded' : expanded}">
      <div id="minimize" @click="changeExpand" v-bind:class="{'expanded' : expanded}"></div>
      <div v-show="expanded" id="detailsContainer">
        <h3 class="stradimTitle">Points d’intérêt</h3>
        <div id="details">
          <div class="legendItem" id="stradimItem"> 
            <label class="switch"
            >
            </label>            
            <img class="little_picto" src="/img/pictos/reduced/triangle.svg"  />
            <div class="legendSpanContainer">
              <span> Programmes Stradim</span>
            </div>             
          </div>          
          <div class="legendItem">
            <label class="switch"
            >
              <input type="checkbox" 
                v-model="okanMain.showPOI"
                @change="updateMarkers(-1, okanMain.showPOI)"
                @load="updateMarkers(-1, okanMain.showPOI)"
              >
              <span 
                class="slider categoryAll"
              ></span>
            </label> 
            <img class="little_picto" src="/img/pictos/reduced/neutre.svg"  />
            <div class="legendSpanContainer">
              <span> Tout</span>
            </div>             
          </div>
          <div class="categoryItem" 
            v-for="( category) in categories"
            v-bind:key="category.id"
          >
          <h6 class="categoryTitle">{{category.label}}</h6>
            <div class="legendItem" 
              v-for="(picto, pictoIndex) in pictos" 
              v-bind:key="pictoIndex"
              v-show="picto.category == category.id"
            >
              <label class="switch"
              v-if="picto.category == category.id"
              >
                <input type="checkbox" 
                  v-model="picto.display"
                  @change="updateMarkers(picto.id, picto.display)"
                >
                <span 
                  class="slider "
                  v-bind:class="'category' + picto.category"
                ></span>
              </label>  
              <img class="little_picto" v-bind:src="picto.reduceUrl"
                 v-if="picto.category == category.id"
               />
              <div class="legendSpanContainer"
                 v-if="picto.category == category.id"
              >
                <span>{{picto.name}}</span>
              </div>
            </div>
          </div>
          <div id="endScroll"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Recherche from "../recherche.js";

export default {
  name: "clientUI",
  components: {},
  props: {
    okanMain: Object,
    pictos: Array,
    biens_optionList: Array,
  },
  data: function() {
    return {
      expanded: true,
      rech_expanded : true,
      rech: new Recherche(),
      options_expanded: new Array(30).fill(this.okanMain.showPOI),
      categories:[
        {
          id:6,
          label:"Education"
        },
        {
          id:1,
          label:"Transport"
        },
                {
          id:2,
          label:"Service public"
        },
                {
          id:3,
          label:"Commerce"
        },
                {
          id:4,
          label:"Sports & loisirs"
        },
                {
          id:5,
          label:"Santé"
        },        
      ]
    };
  },
  mounted() {
    this.rech.init(this.okanMain);
  },
  created() {},
  destroyed() {},
  methods: {
    setExpandedArray() {
      this.options_expanded = new Array(
        this.rech.selectedProgram.biens.length
      ).fill(false);
    },
    toggleOptions(index) {
      if (
        index > this.options_expanded.length ||
        this.options_expanded[index] == undefined ||
        this.options_expanded[index] == null
      ) {
        this.options_expanded[index] = false;
      }
      var ori_value = this.options_expanded[index];
      this.setExpandedArray();
      this.options_expanded[index] = !ori_value;
    },
    displayBienOptions(_array, maxLength = 20) {
      var str = "";
      _array.forEach(element => {
        str += element + ", ";
      });
      if (str != "") str = str.substring(0, str.length - 2);
      if (str.length > maxLength) {
        str = str.substring(0, maxLength - 3) + "...";
      }
      return str;
    },
    changeExpand() {
      this.expanded = !this.expanded;
    },
    changeRechExpand(){
      this.rech_expanded = !this.rech_expanded;
    },
    updateMarkers(id, show) {
      this.okanMain.showHideMarkers(id, show);
    },
    newCriteria() {
      this.rech.addCriteria();
    },
    deleteCriteria(criteriaIndex) {
      this.rech.removeCriteria(criteriaIndex);
    },
    searchForMatches() {
      this.rech.calculate();
    },
    pointToProgram(program) {
      if (program == this.rech.selectedProgram)
        this.rech.selectedProgram = null;
      else {
        this.rech.selectedProgram = program;
        this.rech.okanMain.map.map.jumpTo({
          center: [program.long, program.lat],
          zoom: 17
        });
      }
    }
  }
};
</script>

<style src="../assets/css/clientUI.css"></style>

