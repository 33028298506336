/* eslint-disable no-console*/

class ProxyCriteria{
    constructor(){
        this.more = false;   //more or less
        this.dist = 1;
        this.target = 20;
    }
}

var internalThis = null;

export default class Recherche {
    constructor() {

        this.allow_proxy = false;
        this.proxy_criteria = [];
        this.results= [];

        this.okanMain = null;
        this.fullData = null;

        this.type= {
            enabled: false,
            values: ["Appartement", "Maison"]
        };

        this.pieces={
            enabled: false,
            min:1,
            max: 1
        };

        this.options={
            enabled: false,
            values: ["terrasse","jardin"]
        }

        this.superficie={
            enabled: false,
            min:1,
            max: 1
        };

        this.prix={
            enabled: false,
            min:1,
            max:1
        };

        this.condition= true;
        this.selectedProgram= null;

        internalThis = this;
    }

    init(okanMainRef){
        setTimeout(() => {
            this.okanMain = okanMainRef;
            this.fullData = this.okanMain.map_data[this.okanMain.mapId];  
            //this.okanMain.log("okanMain set")         
        }, 1000);
    }

    addCriteria(){
        this.proxy_criteria.push(new ProxyCriteria());
    }

    removeCriteria(index){
        this.proxy_criteria.splice(index,1);
    }

    calculate(){
        this.results= [];  
        var workingData = JSON.parse(JSON.stringify(this.fullData.stradim));
        workingData.forEach(program => {
            this.results.push(program);
            var tmpArray = this.results[this.results.length -1].biens;
            this.results[this.results.length -1].biens = [];
            var passCriterias = true;
            if(internalThis.allow_proxy)
            {
                internalThis.proxy_criteria.forEach(criteria =>{
                    if(!this.evaluateCriteria(program, criteria)){
                        passCriterias = false;
                        return;
                    } 
                }); 
            }
            if(passCriterias){
                tmpArray.forEach(bien => {
                    if(internalThis.type.enabled){
                        if (!internalThis.type.values.includes(bien.type))
                            return;
                    }
                    if(internalThis.pieces.enabled){
                        if((internalThis.pieces.max != null && bien.pieces > internalThis.pieces.max) || 
                            (internalThis.pieces.min != null && bien.pieces < internalThis.pieces.min))
                            return;
                    }
                    if(internalThis.options.enabled){
                        var _ok = true;

                        internalThis.options.values.forEach(element => {
                            if(!bien.options.includes(element)){
                                _ok = false;
                            }
                        });
                        if(!_ok) return;
                    }
                    if(internalThis.superficie.enabled){
                        if((internalThis.superficie.max != null && bien.superficie > internalThis.superficie.max) || 
                            (internalThis.superficie.min != null && bien.superficie < internalThis.superficie.min))
                            return;                    
                    }
                    if(internalThis.prix.enabled){
                        if((internalThis.prix.max != null && bien.prix > internalThis.prix.max) || 
                            (internalThis.prix.min != null && bien.prix < internalThis.prix.min))
                            return;                    
                    } 
                    //on a passé tout les tests : le bien matche !!! 
                    this.results[this.results.length -1].biens.push(bien);    
                });   
            }
            if(this.results[this.results.length -1].biens.length == 0)
                this.results.splice(this.results.length -1,1);
        });
    }

    evaluateCriteria(program, criteria){
        var found = false;
        var atLeast1Type = false;
        this.fullData.POI.forEach(poi =>{
            if(poi.type == criteria.target){
                atLeast1Type = true;
                var dist = this.calcDistance(program, poi);
                if(criteria.more == "true")
                {
                    if (dist > criteria.dist)
                        found = true;
                }
                else
                {
                    if (dist < criteria.dist)
                        found = true;                    
                }
            }
        });
        if(atLeast1Type)
            return found;
        else{
            return criteria.more;   // si aucun POI du type recherché on est toujours à plus de et jamais à moins de
        }
    }


/*********************************************************************************************************************
 ************************           Maths                        **************************************************** 
 **********************************************************************************************************************/  

    calcDistance(program,POI){

        var radlat1 = Math.PI * program.lat/180;
		var radlat2 = Math.PI * POI.lat/180;
		var theta = program.long-POI.long
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;

		return dist;
    }
}