/* eslint-disable no-console*/

var POIThis = null;
var posUpdater = null;

class _bien{
    constructor(){
        this.type = null,
        this.nb_pieces = null,
        this.superficie = null,
        this.options = [],
        this.prix = null,
        this.quantite = 1
    }               
}


export default class okanPOI{
    constructor(params){
        this.div = params.div == null ? null : params.div;
        this.marker = params.marker == null ? null : params.marker;
        this.externalID = params.id == null ? null : params.id;
        this.id = params.id == null ? null : params.id;
        this.name = params.name == null ? null : params.name;
        this.long = params.long == null ? null : params.long;
        this.lat = params.lat == null ? null : params.lat;
        this.desc = params.desc == null ? null : params.desc;
        this.url = params.url == null ? null : params.url;
        this.biens = params.biens == null ? [] : params.biens;
        this.poiType = params.poiType == null ? 20 : params.poiType;
        this.htmlPopup = null;
        this.poiStradim = params.poiStradim == null ? false : params.poiStradim;
        this.image = params.image == null ? null : params.image;
        this.ville = params.ville == null ? null : params.ville;

        this.okanMain = params.okanMain == null ? null : params.okanMain;

        this.isBeingDragged = false;

        if(params.poiType == null){
            this.okanMain.selectedPOI = this;
            this.okanMain.admin_popup = true;
        }
            

        POIThis = this;

    }

    addBien(){
        this.biens.push(new _bien());
    }

    marker_update(){
        var oldType = this.div.classList.value.split(' ').filter(_class => {
            if(_class.substring(0,5)== 'type_') return _class;
        });
        this.div.classList.remove(oldType);
        this.div.classList.add('type_'+this.poiType); 
    }

    setId(newID){
        this.id = newID;
        this.div.setAttribute("arrayId", newID);
        this.popup_update();
    }

    popup_update(){
        this.htmlPopup = "";
        if(this.okanMain.admin){
            this.htmlPopup += '<div id="editPOI" type="'+ this.poiStradim +'" arrayId="'+this.id+'"></div><div id="deletePOI" type="'+ this.poiStradim +'" arrayId="'+this.id+'"></div>'
        }

        if (this.poiStradim){
            /*
            if(this.ville != null)
                this.htmlPopup += '<div class="panoVille"><span>' + this.ville + '</span></div>'; */           
            this.htmlPopup += '<h4><span class="puce">▲ </span>' + this.name + '</h4>' ; 
            if(this.url != null)
                this.htmlPopup += '<a href="'+ this.url +'" id="popupLink_'+ this.id +'" target="_blank" rel="noopener noreferrer" ><img class="popupImage"  src="' + this.image + '"></a>';
        }
        else{
            if ((this.name != null && this.name != undefined && this.name != "") || (this.desc != null && this.desc != undefined && this.desc != "")){
                this.htmlPopup += '<h4>' + this.name + '</h4>';
                if (this.desc != null && this.desc != undefined && this.desc != ""){
                    this.htmlPopup += '<p>' + this.desc.replace('/\n/g', '<br/>') +'</p>';
                }
            }
        }
        this.marker.getPopup().setHTML(this.htmlPopup);
    }

    setRelocStart(){
        this.isBeingDragged = true;
        this.marker.draggagle = true;
        this.div.classList.add('draggable');
        posUpdater = window.setInterval(()=>{
            this.marker.setLngLat([this.okanMain.map.mouseCursor.coords.long, this.okanMain.map.mouseCursor.coords.lat]);
            this.long = this.okanMain.map.mouseCursor.coords.long;
            this.lat =  this.okanMain.map.mouseCursor.coords.lat;
        },100);
    }

    setRelocEnd(){
        this.isBeingDragged = false;
        this.marker.draggagle = false;
        this.div.classList.remove('draggable');
        clearInterval(posUpdater);
    }

    setListeners(){

        this.div.addEventListener('click', ()=>{
            //POIThis.marker.togglePopup();
            if(POIThis.okanMain.admin){
                //console.log("poi set as selected before",POIThis.okanMain.selectedPOI == null ? "none" : POIThis.okanMain.selectedPOI.name);
                this.okanMain.selectedPOI = this;      
                //console.log("poi set as selected after",POIThis.okanMain.selectedPOI == null ? "none" : POIThis.okanMain.selectedPOI.name);      
            }
            else{
                var element = document.getElementById('popupLink_'+ this.id);
                if(element != null && element != undefined)
                    element.click();
            }
        });
        if(this.okanMain.admin){
            this.div.addEventListener('dblclick', ()=>{
                this.setRelocStart();
            });
          
        //TODO set currentPOI
        }else{
            if(this.htmlPopup != null && this.htmlPopup != undefined && this.htmlPopup != ""){
                this.div.addEventListener('mouseenter', ()=>this.marker.togglePopup()); 
                this.div.addEventListener('mouseleave', ()=>this.marker.togglePopup()); 
                this.marker.getPopup().closeOnClick = true;
            }
        }        
    }    


}