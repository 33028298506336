<template>
    <div 
        id="mapLayer"
        v-bind:okanMain="okanMain"
    >
        
        <div id="mapContainer">
			<div id="vignette"></div>
			<div id="map"></div>
			<canvas 
				id="drawingCanvas"
				v-show="okanMain.admin && okanMain.map.allowDragging"
			>
			</canvas>
		</div>    
    
    </div>
</template>

<script>


export default {
    name: 'mapLayer',
    components: {

    },
    props: {
        okanMain : Object,
    },
    data: function() {
        return {
            
        }
    },
    mounted() {

    },
    created() {

    },
    destroyed() {

    },
    methods : {

    }
}
</script>

<style src="../assets/css/mapLayer.css"></style>

