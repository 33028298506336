import okanMap from './okanMap.js';
import JQuery from 'jquery'
let $ = JQuery
/* eslint-disable no-console*/


var internalThis = null;

export default class okanMain{
    constructor(context){
        this.context=context;

        this.admin = true;
        this.admin_popup = false;
        this.map = new okanMap(this);
        this.mapId = -1;
        this.map_data = [];

        this.selectedZone = 0
        this.selectedPOI = null;
        this.creationMode = false;

        this.POIs = [];
        this.programs = [];
        this.showPOI = true;

        this.badUrlParams = false;
        this.autoloadPrgm = null;

        this.showLeftPanel = false;

        internalThis = this;
    }

    init(){
        //console.log("Main init");
        if(!this.admin){    //getting mapId from url parameters
            var url = document.location.href;

            url = url.replace('?','');

            var splittedUrl = url.split('/');
            url = splittedUrl[splittedUrl.length -1];
            //console.log("args", url);
            var strurl = "";
            url.split('&').forEach(element =>{
                //strurl = element;
                if(element.split('=')[0] == "map")
                    strurl =  element.split('=')[1];
                //    console.log("found mapiD", strurl)
                if(element.split('=')[0] == "prgm"){
                    
                    this.autoloadPrgm = element.split('=')[1];
                //    console.log("found prgm", this.autoloadPrgm);
                }
            });
            
            var _id = parseInt(strurl);
            if(isNaN(_id)){
                //this.badUrlParams = true;
                this.loadPrgm(-1); //va planter et lancer la carte de france
            }   
            else{
                this.showPOI = false;

                this.mapId =_id;
                this.map.init();
                this.loadZone(_id);
                this.showHideMarkers(-1,this.showPOI);
            }             
        }
        else
            this.map.init();  
    }

    loadZone(id){
        this.mapId = id;
        //removing existing markers
        var existingMarkers = document.getElementsByClassName('marker');
        Array.from(existingMarkers).forEach(marker => {
            marker.remove();
        });
        if(this.autoloadPrgm == null){
            this.map.map.jumpTo({'center':this.map_data[this.mapId].map.center, 'zoom': this.map_data[this.mapId].map.maxZoom});
            if(!this.admin)
                this.map.map.fitBounds(this.map_data[this.mapId].map.bounds);            
        }

        this.loadData();       

        if(this.autoloadPrgm != null)
            this.loadPrgm(this.autoloadPrgm); 
            
        $(document).on('click', "#editPOI", function(){
            internalThis.editPOI();
        }); 

        $(document).on('click', "#deletePOI", function(){
            var id = this.getAttribute('arrayId');
            var type = this.getAttribute('type');
            internalThis.deletePOI(type,id);
        });    
        
        this.showLeftPanel = true;
    }

    loadData(){
        this.programs = [];
        this.POIs = [];

        // on load d'abord les POI puis les programmes Stradim pour ne pas avoir à gérer de Z-index (z-index = 2 passe au dessus des popups)

        this.map_data[this.mapId].POI.forEach(element => {
            element.poiStradim = false;
            element.poiType = element.type;
            this.POIs.push(this.map.createMarker(element));
        });
        //console.log("other POI loaded", this.POIs);
        this.map_data[this.mapId].stradim.forEach(element => {
            element.poiStradim = true;
            element.poiType = 'Stradim';
            element.externalID = element.id;
            this.programs.push(this.map.createMarker(element));
        });
        this.makeIds(this.programs);
        this.makeIds(this.POIs);
    }

    setNewZone(){
        var name = prompt('Entrez le nom de cette nouvelle zone. Laissez vide pour ne pas enregistrer.');
        if (name != null && name != undefined && name != ""){
            var newmap = {
                    "map" : {
                        "id": this.map_data.length,
                        "name" : name,
                        "maxZoom" : this.map.map.getZoom(),
                        "center" : this.map.map.getCenter(), 
                        "bounds" : this.map.bounds
                    },
                    "stradim":[
                    ],
                    "POI": [
                    ]
                }        
            this.map_data.push(newmap);
        }
        this.map.setDrawingMode(false); 
    }

    showHideMarkers(id, show){
        //console.log("okanMap showHideMarkers")

        if(id == -1 ){  //all
            for(var i=0; i< this.context.pictos.length; i++){
                this.context.pictos[i].display = show;
                this.showHideMarkers(i, show);
            }
        }
        else{
            var matchingMarkers = document.getElementsByClassName('type_'+id);
            Array.from(matchingMarkers).forEach(marker => {
                if(show)
                    marker.style.display = "block";
                else
                    marker.style.display = "none";
            }); 
        }       
    }

    loadPrgm(externalID){

        //console.log("function param", externalID)
        var target = this.programs.find(program => {
            //console.log("elem",program);
            if(program.externalID == externalID){
               // console.log("found",program);
                return program;
            }
        });  
        //console.log("target", target);
        if (target!=null){  
            this.map.reset({
                center: [target.long, target.lat],
                zoom : 16,
                bounds : [[Number(target.long) -.025, Number(target.lat) -.008],[Number(target.long) +.025, Number(target.lat) +.008]]
            });
            this.loadData();
            this.showPOI = true;
            this.showHideMarkers(-1,this.showPOI);
        }
        else{
            this.badUrlParams = true;
            // ici c'est super sale mais c'est pas possible de fitToBounds une fois la carte initialisée
            //this.map.init();
            this.map.reset({
                center: [0.4150652250206974, 45.94036450863308],
                zoom : 5.59,
                bounds : [[-6.724832764665621, 42.26873593493488],[7.117313647665469, 51.844920471842954]]
            }); 
            this.showPOI = false;
            this.showHideMarkers(-1,this.showPOI);
        }
                 
    } 

    editPOI(){
        this.admin_popup = true;
    }

    deletePOI(type,id){
        if (confirm("Etes-vous sûr(e) de vouloir supprimer le " + 
                 (this.selectedPOI.poiStradim ? 'Programme ' : 'Point d\'intérêt ')  + this.selectedPOI.name + "?")){
            //console.log("delete", type + "-" + id)
            
            if(type == true || type == "true"){
              //  console.log(" program before",this.programs);
                this.programs.splice(id,1);
                this.makeIds(this.programs);
              //  console.log("program after",this.programs);
            }
            else{
             //   console.log("POI before",this.POIs);
                this.POIs.splice(id,1);
                this.makeIds(this.POIs);
              //  console.log("POI after",this.POIs);
            }
            this.selectedPOI.div.remove();
            if(this.selectedPOI.marker.getPopup().isOpen())
                this.selectedPOI.marker.togglePopup();
            this.selectedPOI = null;
        }
    }

    makeIds(_array){
        _array.forEach((element,index)=>{
            element.setId(index);
        });
    }

    findPOI(type,id){
     //   console.log('looking for id :', id )
        if (type == "true"){            
            this.selectedPOI = this.programs.find(program => {
                if(program.id== id){
          //          console.log("found program")
                    return program;
                } 
            });               
        } 
        else{
            this.selectedPOI = this.POIs.find(poi => {
                if(poi.id== id){
                   // console.log("found poi")
                    return poi;
                } 
            });                   
        }
    }

    prepareAndSave(){
        var stradimArray = [];
        var poiArray = [];
        this.programs.forEach((element)=>{
           // console.log('preparing data for ', element);
            var tmpstradim = {
                id: element.id,
                externalID: element.externalID,
                name: element.name,
                long: element.long,
                lat: element.lat,
                url: element.url,
                image: element.image,
                biens: element.biens,
                ville : element.ville
            };
            stradimArray.push(tmpstradim);           
        });
        this.POIs.forEach((element)=>{
            var tmppoi = {
                name: element.name,
                long: element.long,
                lat: element.lat,
                desc: element.desc,
                type: element.poiType
            };
            poiArray.push(tmppoi);

        });
        this.map_data[this.mapId].stradim = stradimArray;
        this.map_data[this.mapId].POI = poiArray;

        setTimeout(() => {
            this.saveToCloud();
        }, 550);
        
    }

    log(string = '', obj){
        console.log(string, obj);
    }

    /*********************************************************************************************************************
     ************************           Serveur                        **************************************************** 
    **********************************************************************************************************************/    
    saveToCloud(){
        var xhr = new XMLHttpRequest();
        //xhr.open("POST", 'https://cartographie-gestion.stradim.clients.sdv.fr/serverdata/upload.php', true);
        xhr.open("POST", 'https://mapstradimadmin.okenite.xyz/serverdata/upload.php', true);

        xhr.setRequestHeader("Access-Control-Allow-Origin" , "*");

        xhr.setRequestHeader("Content-Type", "multipart/form-data");
        
        xhr.onreadystatechange = function() { 
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                console.log(xhr.responseText);
                alert("les modification ont bien été enregistrées.");
            }
        }

        if(this.mapId == null || this.mapId == undefined || this.map_data == null || this.map_data == undefined || this.map_data[this.mapId] == null || this.map_data[this.mapId] == undefined ){
            var alertString = "un problème est survenu lors de la sauvegarde\r\n";
            alertString +="merci de faire une capture de cette fenêtere et de l'envoyer à sp@okan3d.com\r\n";
            alertString +="this.mapId : " + this.mapId + "\r\n";
            alertString += "this.map_data[this.mapId] : " + this.map_data

            alert(alertString);
            return;
        }

        var dataToSend = {
            id: this.mapId,
            data: this.map_data[this.mapId]
        }
        console.log("sending to server", dataToSend);
        xhr.send(JSON.stringify(dataToSend));        
    }

    loadFromCloud(){
        var xhr = new XMLHttpRequest();
        xhr.open("POST", 'https://cartographie-gestion.stradim.clients.sdv.fr/serverdata/download.php', true);
        //xhr.open("POST", 'https://mapstradimadmin.okenite.xyz/serverdata/download.php', true);

       // xhr.setRequestHeader("Access-Control-Allow-Origin" , "*");
        //xhr.withCredentials = "false";
        xhr.setRequestHeader("Content-Type", "multipart/form-data");
        
        xhr.onreadystatechange = function() { 
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                internalThis.map_data = JSON.parse(xhr.responseText);
                console.log("data loaded", internalThis.map_data);
                internalThis.init();
            }
        }
        xhr.send("");  
    }    
}